import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Home_banner from '../Components/Home_banner';
import Build_clan from '../Components/Build_clan';
import Stay_informed from '../Components/Stay_informed';

function Home() {
  return (
    <>
      <Header active='home' />
      <main>
        <Home_banner />
        <Build_clan />
        <Stay_informed />
        <Footer />
      </main>
    </>
  );
}

export default Home;
