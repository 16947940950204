import { useEffect, useState } from 'react';

function Stay_informed() {
  return (
    <div className='stay_infrmd' style={{ background: '#160038' }}>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12'>
            <div className='stay_img'>
              <img src='assets/images/stay-infrmd.png' className='img-fluid' />
            </div>
          </div>
          <div className='col-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 offset-lg-1 offset-xl-1 '>
            <div className='stay_infrm_txt'>
              <h2 className='resp_h2'>STAY INFORMED</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis
                maecenas nulla enim nunc. Nibh in odio accumsan laoreet ipsum
                scelerisque phasellus feugiat a. Arcu sed eu fames amet id sit.
                Posuere tristique nulla donec ante augue est mauris.
              </p>
            </div>
            <div className='social_icons'>
              <h5>FOLLOW VIKING LEGENDS ON</h5>
              <div className='d-flex'>
                <div className=''>
                  <img className='img-fluid' src='assets/images/icon1.png' />
                </div>
                <div className=''>
                  <img className='img-fluid' src='assets/images/icon2.png' />
                </div>
                <div className=''>
                  <img className='img-fluid' src='assets/images/icon3.png' />
                </div>
                <div className=''>
                  <img className='img-fluid' src='assets/images/icon4.png' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Stay_informed;
