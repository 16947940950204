import { useEffect, useState } from 'react';

function Home_banner() {
  return (
    <div className='home_banner'>
      <div className='row'>
        <div className='col-lg-12'>
          <center>
            <img
              className='logo_banner_font'
              src='assets/images/logo_banner.png'
              alt='Los Angeles'
            />
            <hr className='hr' />
          </center>
          <center>
            <h2 className='banner-text'>The Legends in your </h2>
            <h2 className='banner-text1'> HANDS</h2>
          </center>
          <center className='mt-4'>
            <button
              className='play-game-button'
              style={{
                background: '#e1377b',
                color: '#fff',
                border: '1px solid #e1377b',
              }}
            >
              Play game
            </button>
            <button
              className='read-more-button'
              style={{
                background: '#ffffff',
                color: '#000000',
                border: '1px solid #ffffff',
              }}
            >
              Learn more
            </button>
          </center>
        </div>
        {/* <div className="col-lg-3">
            <hr size="30" />
          </div>
          <div className="col-lg-2">
          </div> */}
      </div>

      {/* <div className="container">
        

      </div> */}

      {/* <div id="demo" className="carousel slide custom_slide" data-bs-ride="carousel"> */}

      {/* <!-- Indicators/dots --> */}
      {/* <div className="carousel-indicators">
          <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
          <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
          <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
        </div> */}

      {/* <!-- The slideshow/carousel --> */}
      {/* <div className="carousel-inner custom_carousel">
          <div className="carousel-item active">
            <img src="assets/images/viking_banner.png" width="100%" height="100%" alt="Los Angeles"
              className="d-block w-100" /> */}
      {/* <!--  <div className="carousel-caption">
                    <img src="assets/images/viking-text.png" className="bg_text_img">
                      <hr className="h_line">
                        <h1>The Legends in your HANDS</h1>
                        <div className="container mt-3">

                          <div className="btn-group">
                            <button type="button" className="btn btn-primary play">Play game</button>

                          </div>
                          <div className="btn-group">
                            <button type="button" className="btn btn-primary learn">Learn more</button>

                          </div>
                        </div>
                      </div> --> */}
      {/* </div>
          <div className="carousel-item">
            <img src="assets/images/banner2.png" alt="Los Angeles" className="d-block w-100 " /> */}
      {/* <!-- <div className="carousel-caption">
                        <img src="assets/images/viking-text.png" className=" bg_text_img">
                          <hr className="h_line">
                            <h1>The Legends in your HANDS</h1>
                            <div className="container mt-3">

                              <div className="btn-group">
                                <button type="button" className="btn btn-primary play">Play game</button>

                              </div>
                              <div className="btn-group">
                                <button type="button" className="btn btn-primary learn">Learn more</button>

                              </div>
                            </div>
                          </div> --> */}
      {/* </div>
          <div className="carousel-item">
            <img src="assets/images/banner3.png" alt="Los Angeles" className="d-block w-100 bg_text_img" /> */}
      {/* <!-- <div className="carousel-caption">
                            <img src="assets/images/viking-text.png" className=" bg_text_img">
                              <hr className="h_line">
                                <h1>The Legends in your HANDS</h1>
                                <div className="container mt-3">

                                  <div className="btn-group">
                                    <button type="button" className="btn btn-primary play">Play game</button>

                                  </div>
                                  <div className="btn-group">
                                    <button type="button" className="btn btn-primary learn">Learn more</button>

                                  </div>
                                </div>
                              </div> --> */}
      {/* </div>
        </div> */}

      {/* <!-- Left and right controls/icons --> */}
      {/* <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
          <span className="carousel-control-prev-icon"></span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
          <span className="carousel-control-next-icon"></span>
        </button> */}
      {/* </div> */}
    </div>
  );
}
export default Home_banner;
