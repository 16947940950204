import { useEffect, useState } from 'react';

function Build_clan() {
  return (
    <div className='build_clan pt-3' style={{ background: '#160038' }}>
      <div className='container' style={{ background: '#80558C' }}>
        <h2 className='text-center resp_h2 pt-3'>BUILD YOUR CLAN</h2>
        <div className='row pt-7 pb-4 '>
          <div className='col-12 col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 card-top'>
            <div className='card card1'>
              <div className='content'>
                <h2 className='titlee'>Mountain View</h2>
                <p className='copy'>
                  Check out all of these gorgeous mountain trips with beautiful
                  views of, you guessed it, the mountains
                </p>
              </div>
            </div>
          </div>
          <div className='col-12 col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 card-left'>
            <div className='card card2'>
              <div className='content'>
                <h2 className='titlee'>Mountain View</h2>
                <p className='copy'>
                  Check out all of these gorgeous mountain trips with beautiful
                  views of, you guessed it, the mountains
                </p>
              </div>
            </div>
          </div>
          <div className='col-12 col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 card-right'>
            <div className='card card3'>
              <div className='content'>
                <h2 className='titlee'>Mountain View</h2>
                <p className='copy'>
                  Check out all of these gorgeous mountain trips with beautiful
                  views of, you guessed it, the mountains
                </p>
              </div>
            </div>
          </div>
          <div className='col-12 col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 card-bottom'>
            <div className='card card4'>
              <div className='content'>
                <h2 className='titlee'>Mountain View</h2>
                <p className='copy'>
                  Check out all of these gorgeous mountain trips with beautiful
                  views of, you guessed it, the mountains
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--Build clan End --> */}
      <div className='clearfix'>...</div>
      <div className='game_bg' style={{ background: '#160038' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12'>
              <div className='game_experience_img'>
                <img src='assets/images/logo3.png' className='img-fluid' />
              </div>
            </div>
            <div className='col-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 offset-lg-1 offset-xl-1'>
              <div className='game_exp_txt'>
                <h2 className='resp_h2'>THE GAMING EXPERIENCE</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis
                  maecenas nulla enim nunc. Nibh in odio accumsan laoreet ipsum
                  scelerisque phasellus feugiat a. Arcu sed eu fames amet id
                  sit. Posuere tristique nulla donec ante augue est mauris.
                </p>
                <img
                  src='assets/images/google-play-badge.png'
                  className='img-fluid'
                />
              </div>
            </div>
          </div>

          <div className='line_img1'>
            <img
              src='assets/images/line1.png'
              className='img-fluid line-width'
              alt='Gaming Experience'
              title='Gaming Experience'
            />
          </div>
          <div className='clearfix'>...</div>

          <hr className='lch_hr' />
          <div className='row'>
            <div className='col-12 col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 '>
              <div className='launch_schd_txt'>
                <h2 className='text-left resp_h2'>LAUNCH SCHEDULE</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis
                  maecenas nulla enim nunc. Nibh in odio accumsan laoreet ipsum
                  scelerisque phasellus feugiat a. Arcu sed eu fames amet id
                  sit. Posuere tristique nulla donec ante augue est mauris.
                </p>
              </div>
              <div className='container no-padding'>
                <div className='row'>
                  <div className='d-flex game_anim'>
                    <div className='launch_mtr'>
                      <img
                        src='assets/images/launchscedule1.png'
                        className='img-fluid'
                      />
                    </div>
                    <div
                      className='game-screen animate wow fadeInLeft'
                      data-wow-duration='2s'
                    ></div>
                  </div>
                  {/* <!-- <div className="game-screen animate__animated animate__backInLeft"></div> --> */}
                </div>
              </div>
            </div>
            <div className='col-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 offset-lg-1 offset-xl-1'>
              <div className='launch_schd_img'>
                <img src='assets/images/logo1.png' className='img-fluid' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='line_img2'>
        <img
          src='assets/images/line2.png'
          className='img-fluid line-width2'
          alt='Gaming Experience'
          title='Gaming Experience'
        />
      </div>
      <div className='clearfix'>...</div>
    </div>
  );
}
export default Build_clan;
